// 登录接口
import {
	axios
} from '../../utils/http.js'

const DevApi = {
	DevParameSync: '/v3/Device/Parameter_Sync',//同步参数



	DevList: '/v3/Device/List',//设备列表
	DevEdit: '/v3/Device/Edit',//主机设置>主机配置
	DevSenList: '/v3/DeviceSensor/List',//传感器列表+
	DevParameter_Get: '/v3/Device/Parameter_Get',//主机参数获取
	DevParameter_List: '/v3/Device/Parameter_List',//主机参数获取
	DevParameter_Pu: '/v3/Device/Parameter_Pu',//主机参数获取-修改
	
	DevRes: '/v3/Device/RestartShutdown',//重启-关机
	DevTask: '/v3/Device/DeviceTask_Post',//设备管理按钮获取数据-远程启停按钮
	DevLog: '/v3/Device/Log',//设备管理按钮获取数据-远程启停按钮
	ProUpList: '/v3/ProductUpgrade/List',//设备管理按钮获取数据-远程启停按钮
	DevAdd: '/v3/Device/Add',//新增主机设备
	DevUpgrade: '/v3/Device/ProductUpgrade',//新增主机设备
	DevParGet: '/v3/DeviceSensor/Parameter_Get',
	DevParParPu: '/v3/DeviceSensor/Parameter_Pu',
	DevSenEdit: '/v3/DeviceSensor/Edit',//单个传感器设置修改-视图订阅-屏蔽数据-数据排序
	DevSenParList: '/v3/DeviceSensor/Parameter_List',//单个传感器设置获取
	DevGet: '/v3/Device/Get',//单个传感器设置获取
	DevListAll: '/v3/DeviceSensorType/List_All',//单个传感器设置获取
	
	DevDel: '/v3/Device/Del',//设备管理按钮获取数据-删除设备
	DevSync: '/v3/Device/Read_DeviceSensorParameter',//设备管理按钮获取数据-同步传感器
	DevSenParPu: '/v3/DeviceSensor/Parameter_Pu',//单个传感器设置获取
	DevSenDel: '/v3/DeviceSensor/Del',//单个传感器设置删除

    getDepartment: '/v3/JWX/getDepartment',//单位拉去数据
    getColdEquipment: '/v3/JWX/getColdEquipment',//绑定设备
    probeUpload: '/v3/JWX/probeUpload',//传感器探头信息上传
    sensorUpload: '/v3/JWX/sensorUpload',//中继器基础信息上传
    createMaintainInfo: '/v3/JWX/createMaintainInfo',//
    probeRefUpload: '/v3/JWX/probeRefUpload',//冷链设备传感器(探头)关联关系上传接口
    

    findMaintainInfo: '/v3/JWX/findMaintainInfo',//回显

    
}
export function findMaintainInfo(data){
	return axios({
		url:DevApi.findMaintainInfo,
		method: 'post',
		data:data
	})
}
export function createMaintainInfo(data){
	return axios({
		url:DevApi.createMaintainInfo,
		method: 'post',
		data:data
	})
}
export function probeRefUpload(data){
	return axios({
		url:DevApi.probeRefUpload,
		method: 'post',
		data:data
	})
}
export function probeUpload(data){
	return axios({
		url:DevApi.probeUpload,
		method: 'post',
		data:data
	})
}
export function sensorUpload(data){
	return axios({
		url:DevApi.sensorUpload,
		method: 'post',
		data:data
	})
}
export function getColdEquipment(data){
	return axios({
		url:DevApi.getColdEquipment,
		method: 'post',
		data:data
	})
}

export function getDepartment(data){
	return axios({
		url:DevApi.getDepartment,
		method: 'post',
		data:data
	})
}
export function DevParameSync(data){
	return axios({
		url:DevApi.DevParameSync,
		method: 'post',
		data:data
	})
}
export function DevListAll(data){
	return axios({
		url:DevApi.DevListAll,
		method: 'post',
		data:data
	})
}
export function DevGet(data) {
	return axios({
		url: DevApi.DevGet,
		method: 'post',
		data:data
	})
}
export function DevParParPu(data) {
	return axios({
		url: DevApi.DevParParPu,
		method: 'post',
		data:data
	})
}
export function DevParGet(data) {
	return axios({
		url: DevApi.DevParGet,
		method: 'post',
		data:data
	})
}

export function DevUpgrade(data) {
	return axios({
		url: DevApi.DevUpgrade,
		method: 'post',
		data:data
	})
}

export function ProUpList(data) {
	return axios({
		url: DevApi.ProUpList,
		method: 'post',
		data:data
	})
}
export function DevLog(data) {
	return axios({
		url: DevApi.DevLog,
		method: 'post',
		data:data
	})
}
//单个传感器设置修改-视图订阅-屏蔽数据-数据排序
export function DevRes(data) {
	return axios({
		url: DevApi.DevRes,
		method: 'post',
		data:data
	})
}
//单个传感器设置修改-视图订阅-屏蔽数据-数据排序
export function DevParameter_Get(data) {
	return axios({
		url: DevApi.DevParameter_Get,
		method: 'post',
		data:data
	})
}
//单个传感器设置修改-视图订阅-屏蔽数据-数据排序
export function DevSenEdit(data) {
	return axios({
		url: DevApi.DevSenEdit,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据-同步传感器
export function DevSenDel(data) {
	return axios({
		url: DevApi.DevSenDel,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据-同步传感器
export function DevSenParPu(data) {
	return axios({
		url: DevApi.DevSenParPu,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据-同步传感器
export function DevSenParList(data) {
	return axios({
		url: DevApi.DevSenParList,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据-同步传感器
export function DevSync(data) {
	return axios({
		url: DevApi.DevSync,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据-远程启停按钮
export function DevDel(data) {
	return axios({
		url: DevApi.DevDel,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据-远程启停按钮
export function DevTask(data) {
	return axios({
		url: DevApi.DevTask,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据-修改
export function DevEdit(data) {
	return axios({
		url: DevApi.DevEdit,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据-修改
export function DevParameter_Pu(data) {
	return axios({
		url: DevApi.DevParameter_Pu,
		method: 'post',
		data:data
	})
}
//设备管理按钮获取数据
export function DevParameter_List(data) {
	return axios({
		url: DevApi.DevParameter_List,
		method: 'post',
		data:data
	})
}
//传感器列表
export function DevSenList(data) {
	return axios({
		url: DevApi.DevSenList,
		method: 'post',
		data:data
	})
}
//新增主机设备
export function DevAdd(data) {
	return axios({
		url: DevApi.DevAdd,
		method: 'post',
		data:data
	})
}
//设备列表
export function DevList(data) {
	return axios({
		url: DevApi.DevList,
		method: 'post',
		data:data
	})
}